// 注册一个全局自定义指令 `v-html-image-style`
import Vue from 'vue';
Vue.directive('html-image-style', {
  // 当绑定元素插入到DOM中
  inserted: function (el, binding) {
    // 获取绑定的富文本内容
    let content = binding.value;
    // 指定要匹配的域名
    const domain = 'oss.dyxh.cc';
    // 指定要设置的样式，例如width和height
    const style = 'display: block;';
    const inline = ' display: inline;';
    // 使用DOMParser和XMLSerializer处理HTML字符串
    let doc = new DOMParser().parseFromString(content, 'text/html');
    let images = doc.querySelectorAll('img');

    images.forEach(img => {
      // 检查图片的src属性是否包含指定域名
      if (img.src.includes(domain)) {
        // 设置图片的样式
        img.style.cssText = style;
      }
      // if (img.style.cssText.includes('width')) {
      //   // 设置图片的样式
      //   img.style.cssText = img.style.cssText + inline;
      // }
    });

    // 修改视频封面图为第一帧图片
    // 视频第一帧参数
    const posterParam = '&x-oss-process=video/snapshot,t_1,m_fast';
    let videos = doc.querySelectorAll('video');
    videos.forEach(video => {
      // 检查图片的src属性是否包含指定域名
      let videoSrc = video.querySelector('source').src
      console.log(videoSrc);

      if (videoSrc) {
        // 设置图片的样式
        video.poster = videoSrc + posterParam;
      }
    });

    // 序列化修改后的文档为HTML字符串并赋值回元素
    el.innerHTML = new XMLSerializer().serializeToString(doc.documentElement);
  }
});
