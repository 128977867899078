
// 星合页面
const Home = () => import(/* webpackChunkName: 'index' */ '@/views/index/home.vue') //首页
const HomeListArtist = () => import(/* webpackChunkName: 'index-list-artist' */ '@/views/index/list/artist.vue')  //首页-艺人列表
const HomeListAmbassador = () => import(/* webpackChunkName: 'index-list-ambassador' */ '@/views/index/list/ambassador.vue') //首页-品牌代言
// common（other页）
const Test = () => import(/* webpackChunkName: 'test' */ '@/views/other/test.vue') //测试页面
const Search = () => import(/* webpackChunkName: 'search' */ '@/views/other/search.vue') //搜索页面
const Layouts = () => import(/* webpackChunkName: 'layouts' */ '@/layouts/page.vue')
const Error404 = () => import(/* webpackChunkName: 'error404' */ '@/views/other/404.vue') //404
const BusinessInfoBind = () => import(/* webpackChunkName: 'business-infobind' */ '@/views/other/businessInfoBind.vue') //商务信息绑定页面
// 艺人页面
const Artist = () => import(/* webpackChunkName: 'artist' */ '@/views/artist/home.vue') //艺人主页
// 艺人-列表页
const ListHotspot = () => import(/* webpackChunkName: 'list-hotspot' */ '@/views/list/hotspot.vue') //艺人-热点事件
const ListAmbassador = () => import(/* webpackChunkName: 'list-ambassador' */ '@/views/list/ambassador.vue') //艺人-品牌代言
const ListMovieTv = () => import(/* webpackChunkName: 'list-movietv' */ '@/views/list/movietv.vue') //艺人-影视剧
const ListMusic = () => import(/* webpackChunkName: 'list-music' */ '@/views/list/music.vue') //艺人-音乐
const ListVariety = () => import(/* webpackChunkName: 'list-variety' */ '@/views/list/variety.vue') //艺人-综艺
const ListImageText = () => import(/* webpackChunkName: 'list-imageText' */ '@/views/list/imageText.vue') //艺人-图文
const ListBusiness = () => import(/* webpackChunkName: 'list-business' */ '@/views/list/business.vue') //艺人-商务活动
const ListHonor = () => import(/* webpackChunkName: 'list-honor' */ '@/views/list/honor.vue') //艺人-荣誉成就
const ListCharity = () => import(/* webpackChunkName: 'list-charity' */ '@/views/list/charity.vue') //艺人-社会公益
const ListAlbum = () => import(/* webpackChunkName: 'list-album' */ '@/views/list/album.vue') //艺人-图集
// const ListCase = () => import(/* webpackChunkName: 'list-case' */ '@/views/list/case.vue')
// const ListNew = () => import(/* webpackChunkName: 'list-new' */ '@/views/list/new.vue')
// 艺人-详情页
const Detail = () => import(/* webpackChunkName: 'detail' */ '@/views/detail/page.vue') //内容页
const DetailAlbum = () => import(/* webpackChunkName: 'detail-album' */ '@/views/detail/album.vue') //图集详情页
const DetailHonor = () => import(/* webpackChunkName: 'detail-honor' */ '@/views/detail/honor.vue') //荣誉成就详情页
// 东娱页面
const Dongyu = () => import(/* webpackChunkName: 'dongyu' */ '@/views/dongyu/home.vue')
const DongyuListArtist = () => import(/* webpackChunkName: 'dongyu-list-artist' */ '@/views/dongyu/list/artist.vue') //东娱艺人
const DongyuListActivity = () => import(/* webpackChunkName: 'dongyu-list-activity' */ '@/views/dongyu/list/activity.vue') //东娱活动（演唱会，综艺列表）
const DongyuListCase = () => import(/* webpackChunkName: 'dongyu-list-case' */ '@/views/dongyu/list/case.vue') //东娱案例
const DongyuListNew = () => import(/* webpackChunkName: 'dongyu-list-new' */ '@/views/dongyu/list/new.vue') //东娱资讯
const DongyuListCaseLatest = () => import(/* webpackChunkName: 'dongyu-list-case-latest' */ '@/views/dongyu/list/caselatest.vue') //东娱最新案例
const DongyuDetail = () => import(/* webpackChunkName: 'dongyu-detail' */ '@/views/dongyu/detail/page.vue') //东娱详情页
// 子公司页面
const CompanyHme = () => import(/* webpackChunkName: 'company-hme' */ '@/views/company/hme/home.vue')
const CompanyKyyy = () => import(/* webpackChunkName: 'company-kyyy' */ '@/views/company/kyyy/home.vue')
const CompanyMtxh = () => import(/* webpackChunkName: 'company-mtxh' */ '@/views/company/mtxh/home.vue')
const routes = [
    {
        name: "Error404",
        path: '/error404',
        component: Error404
    },
    {
        path: '/',
        name: "home",
        meta: { nav: "home" },
        component: Home,
    },
    {
        name: "Test",
        path: '/test',
        component: Test,
    },
    {
        name: "Search",
        path: '/search',
        component: Search,
    },
    {
        name: "BusinessInfoBind",
        path: '/business/info',
        meta: { title: "东娱传媒" },
        component: BusinessInfoBind,
    },
    {
        path: '/home',
        meta: { nav: "home" },
        component: Layouts,
        children: [
            {
                name: "Home", path: '/home', component: Home,
            },
            {
                name: "HomeListArtist",
                path: 'list/artist',
                meta: { nav: "artist" },
                component: HomeListArtist
            },
            {
                name: "HomeListAmbassador",
                path: 'list/ambassador',
                meta: { nav: "ambassador" },
                component: HomeListAmbassador
            },
            {
                name: "HomeDetail",
                path: 'detail',
                component: Detail
            },
            {
                name: "HomeListHotspot",
                path: 'list/hotspot',
                meta: { nav: "hotspot" },
                component: ListHotspot
            },
            {
                name: "HomeListMovieTv",
                path: 'list/movietv',
                component: ListMovieTv
            },
            {
                name: "HomeListMusic",
                path: 'list/music',
                component: ListMusic
            },
            {
                name: "HomeListVariety",
                path: 'list/variety',
                component: ListVariety
            },
            // {
            //     name: "HomeListCase",
            //     path: 'list/case',
            //     meta: { nav: "case" },
            //     component: ListCase
            // },
            // {
            //     name: "HomeListNew",
            //     path: 'list/new',
            //     meta: { nav: "new" },
            //     component: ListNew
            // },
        ]
    },
    {
        path: '/dongyu',
        meta: { nav: "dongyu", title: "东娱传媒" },
        component: Layouts,
        children: [
            {
                name: 'Dongyu',
                path: '/dongyu',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: Dongyu
            },
            {
                name: "DongyuDetail",
                path: 'detail',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuDetail
            },
            {
                name: "DongyuListArtist",
                path: 'list/artist',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListArtist
            },
            {
                name: "DongyuListHotspot",
                path: 'list/hotspot',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListHotspot
            },
            {
                name: "DongyuListAmbassador",
                path: 'list/ambassador',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListAmbassador,
            },
            {
                name: "DongyuListMovieTv",
                path: 'list/movietv',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListMovieTv,
            },
            {
                name: "DongyuListMusic",
                path: 'list/music',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListMusic,
            },
            {
                name: "DongyuListVariety",
                path: 'list/variety',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListVariety,
            },
            {
                name: "DongyuListCase",
                path: 'list/case',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListCase,
            },
            {
                name: "DongyuListCaseLatest",
                path: 'list/caselatest',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListCaseLatest,
            },
            {
                name: "DongyuListNew",
                path: 'list/new',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListNew
            },
            {
                name: "DongyuListActivity",
                path: 'list/activity',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListActivity
            },
        ]
    },
    {
        path: '/hme',
        meta: { nav: "hme", title: "哈米尔动漫" },
        component: Layouts,
        children: [
            {
                name: 'CompanyHme',
                path: '/hme',
                meta: { nav: "hme", title: "哈米尔动漫" },
                component: CompanyHme
            }
        ]
    },
    {
        path: '/kyyy',
        meta: { nav: "kyyy", title: "鲲一影业" },
        component: Layouts,
        children: [
            {
                name: 'CompanyKyyy',
                path: '/kyyy',
                meta: { nav: "kyyy", title: "鲲一影业" },
                component: CompanyKyyy
            }
        ]
    },
    {
        path: '/mtxh',
        meta: { nav: "mtxh", title: "满天星火" },
        component: Layouts,
        children: [
            {
                name: 'CompanyMtxh',
                path: '/mtxh',
                meta: { nav: "mtxh", title: "满天星火" },
                component: CompanyMtxh
            }
        ]
    },
    {
        path: '/:artist',
        meta: { nav: "artist" },
        component: Layouts,
        children: [
            {
                name: 'Artist',
                path: '/:artist',
                component: Artist,
                // meta: { keepAlive: true }
            },
            {
                name: "ArtistDetail",
                path: 'detail',
                component: Detail
            },
            {
                name: "ArtistDetailAlbum",
                path: 'detail/album',
                component: DetailAlbum
            },
            {
                name: "ArtistDetailHonor",
                path: 'detail/honor',
                component: DetailHonor
            },
            {
                name: "ArtistListHotspot",
                path: 'list/hotspot',
                component: ListHotspot
            },
            {
                name: "ArtistListAmbassador",
                path: 'list/ambassador',
                component: ListAmbassador
            },
            {
                name: "ArtistListMovieTv",
                path: 'list/movietv',
                component: ListMovieTv
            },
            {
                name: "ArtistListMusic",
                path: 'list/music',
                component: ListMusic
            },
            {
                name: "ArtistListVariety",
                path: 'list/variety',
                component: ListVariety
            },
            // {
            //     name: "ArtistListCase",
            //     path: 'list/case',
            //     component: ListCase
            // },
            {
                name: "ArtistListImageText",
                path: 'list/imagetext',
                component: ListImageText
            },
            {
                name: "ArtistListBusiness",
                path: 'list/business',
                component: ListBusiness
            },
            {
                name: "ArtistListHonor",
                path: 'list/honor',
                component: ListHonor
            },
            {
                name: "ArtistListCharity",
                path: 'list/charity',
                component: ListCharity
            },
            {
                name: "ArtistListAlbum",
                path: 'list/album',
                component: ListAlbum
            },
        ]
    },
    {
        path: '*',
        component: () => Error404
    }
]

export default routes