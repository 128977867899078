import Vue from 'vue';

Vue.directive('lazy-background', {
  bind(el, binding) {
    const loadImage = () => {
      const image = new Image();
      image.src = binding.value;
      image.onload = () => {
        el.style.backgroundImage = `url(${binding.value})`;
        el.style.backgroundSize = 'cover'; // 确保背景图片填充并适应容器
        el.style.backgroundPosition = 'center'; // 图片居中显示
      };
    };

    // IntersectionObserver 用于懒加载
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    });

    observer.observe(el);
  },
  update(el, binding) {
    // 当绑定值更新时，重新加载背景图片
    el.style.backgroundImage = 'none'; // 先移除旧的背景图片
    if (binding.value) {
      const image = new Image();
      image.src = binding.value;
      image.onload = () => {
        el.style.backgroundImage = `url(${binding.value})`;
      };
    }
  }
});
