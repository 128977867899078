module.exports = {
  // 艺人
  postFrontArtistHome: "/front/artist/home", //艺人主页
  postFrontArtistNickname: "/front/artist/nickname", //艺人昵称
  postFrontArtistMini: "/front/artist/mini", //艺人基础信息  
  postFrontArtistEndorsementList: "/front/artist/endorsement_list", //代言列表
  postFrontArtistEndorsementYear: "/front/artist/endorsement_year", //代言年份
  postFrontArtistHotList: "/front/artist/hot_list", //热点列表
  postFrontArtistHotClass: "/front/artist/hot_class", //热点分类
  postFrontArtistMovieList: "/front/artist/movie_list", //影视列表
  postFrontArtistMovieClass: "/front/artist/movie_class", //影视分类
  postFrontArtistVarietyList: "/front/artist/variety_list", //综艺列表
  postFrontArtistMusicList: "/front/artist/music_list", //音乐列表
  postFrontArtistVideo: "/front/artist/video", //视频详细
  postFrontArtistPosterList: "/front/artist/poster_list", //海报列表
  postFrontArtistContentList: "/front/artist/content_list", //图文列表
  postFrontArtistContentItem: "/front/artist/content_item", //图文详细
  postFrontArtistUrlList: "/front/artist/url_list", //链接列表
  // **** 20240927 新增 start ****
  postFrontArtistBusinessClass: "/front/artist/business_class", //商务活动分类
  postFrontArtistBusinessList: "/front/artist/business_list", //商务活动列表
  postFrontArtistBusinessItem: "/front/artist/business_item", //商务活动详细
  postFrontArtistHonorList: "/front/artist/honor_list", //荣誉成就列表
  postFrontArtistHonorItem: "/front/artist/honor_item", //荣誉成就详细
  postFrontArtistHonorClass: "/front/artist/honor_class", //荣誉成就分类
  postFrontArtistCharityList: "/front/artist/charity_list", //社会公益列表
  postFrontArtistCharityItem: "/front/artist/charity_item", //社会公益详细
  // **** 20240927 新增 end ****
  // **** 20241012 新增 start **** 
  postFrontArtistAlbumList: "/front/artist/album_list", //图集列表
  postFrontArtistAlbumItem: "/front/artist/album_item", //图集详细
  // **** 20241012 新增 end **** 
  // 平台
  getFrontWebHome: "/front/web/home", //平台主页
  getFrontWebArtistType: "/front/web/artist_type", //艺人分类
  postFrontWebArtistList: "/front/web/artist_list", //艺人列表
  getFrontWebArtistTag: "/front/web/artist_tag", //筛选标签列表
  postFrontWebEndorsementList: "/front/web/endorsement_list", //商务代言列表
  getFrontWebHotClass: "/front/web/hot_class", //热点分类
  postFrontWebHotList: "/front/web/hot_list", //热点列表
  getFrontWebRecommendClass: "/front/web/recommend_case", //精选案例
  getFrontWebCaseType: "/front/web/case_type", //案例分类
  postFrontWebCaseList: "/front/web/case_list", //案例列表
  postFrontWebCaseItem: "/front/web/case_item", //案例详细
  getFrontWebInformationType: "/front/web/information_type", //资讯分类
  postFrontWebInformationList: "/front/web/information_list", //资讯列表
  postFrontWebInformationItem: "/front/web/information_item", //资讯详细
  // 搜索
  postFrontWebSearchCount: "/front/web/search_count", //搜索统计
  postFrontWebSearch: "/front/web/search", //全部搜索
  postFrontWebSearchList: "/front/web/search_list", //分类搜索
  // 商务
  postWxToken: "/wx/token", //微信网页授权
  postFrontUserGetBusinessAuth: '/front/user/get_business_auth', //获取商务（编辑）
  postFrontUserBindBusiness: "/front/user/bind_business", //绑定商务(访客)
  postFrontUserGetBusiness: "/front/user/get_business", //获取商务
  getWxJssdk: "/wx/jssdk", //获取微信JS-SDK参数
  postWxWbLogin: "/wx/web_login", //网页版扫码登录
  // 东娱
  getFrontDongyuhome: "/front/dongyu_t/home", //东娱主页
  getFrontDongyuMini: "/front/dongyu_t/mini", //东娱-基础信息
  postFrontDongyuArtist: "/front/dongyu_t/artist", //东娱-艺人列表
  postFrontDongyuNewsType: "/front/dongyu_t/news_type", //东娱-资讯分类
  postFrontDongyuNewsList: "/front/dongyu_t/news_list", //东娱-资讯列表
  postFrontDongyuCasesList: "/front/dongyu_t/cases_list", //东娱-案例列表
  postFrontDongyuMusicList: "/front/dongyu_t/music_list", //东娱-演唱会列表
  postFrontDongyuVarietyList: "/front/dongyu_t/variety_list", //东娱-剧综列表
  postFrontDongyuArtistTag: "/front/dongyu_t/artist_tag", //东娱-筛选标签列表
  postFrontDongyuArtistList: "/front/dongyu_t/artist_list", //东娱-艺人列表
  postFrontDongyuArtistType: "/front/dongyu_t/artist_type", //东娱-艺人分类
  postFrontDongyuContentList: "/front/dongyu_t/content_list", //东娱-最新案例列表
  postFrontDongyuContentItem: "/front/dongyu_t/content_item", //东娱-最新案例详情
  getFrontDongyuCasesType: "/front/dongyu_t/cases_type", //东娱-案例分类
  postFrontDongyuArtistExclusive: "/front/dongyu_t/artist_exclusive", //东娱-独家艺人列表
}

